import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout"
import ProductGridItem from "../components/product-grid-item"

// PRODUCTS GRID
const Wrapper = styled.div`
  //margin-top: 70px;
  //margin-bottom: 50px;
  margin: 100px auto;

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    width: 100%;
  }

  .grid-item {
    width: 350px;
    min-width: 350px;
    @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
      width: calc(50% - 10px);
    }
    @media only screen and (max-width: ${props =>
        props.theme.breakpoints.small}) {
      width: 100%;
    }
  }
`

const FlexGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    margin: auto auto 48px;
    width: 90%;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    justify-content: center;
  }

  .grid-text {
    background: ${props =>
      props.theme.palette[props.bgCol] || props.theme.palette.navCol};
  }
`
/*
@media only screen and (max-width: ${props => props.theme.breakpoints.medium}}) {
  flex: 0 0 50%;
}

@media only screen and (max-width: 570px) {
  flex: 0 0 100%;
}
*/
const GridItem = styled.div`
  flex-shrink: 0;
  width: 350px;
  min-width: 350px;
  margin: 5px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(50% - 10px);
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    width: 100%;
    margin: 5px 0px;
  }
`

const GridText = styled.div`
  padding: 48px 32px;
  width: calc(350px - 64px);
  margin: auto;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: ${props =>
    props.theme.palette[props.color] || props.theme.palette.lightText};

  h2 {
    margin-bottom: 14px;
    font-size: 2.3rem;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }

  p {
    font-weight: 500;
  }
`

const GridImage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const TitleContainer = styled.div`
  background: rgba(29, 141, 190, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.palette.lightText};
`

const Title = styled.h4`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.lightText};
`

const HR = styled.hr`
  width: 50%;

  border: 1px solid
    ${props =>
      props.theme.palette[props.color] || props.theme.palette.lightText};
`

const Image = styled(Img)`
  height: 100%;
`

const Products = options => (
  <StaticQuery
    query={graphql`
      fragment image on File {
        name
        childImageSharp {
          fluid(cropFocus: CENTER) {
            src
            aspectRatio
          }
        }
      }
      {
        photo3: file(name: { eq: "SWT_Weiß" }) {
          ...image
        }
        photo2: file(name: { eq: "OSD_Scene_Eurostyle D338_7004_DSC8898" }) {
          ...image
        }
        sektionaltore: file(name: { eq: "DST_Smart_Style_Anth_Glatt_01" }) {
          ...image
        }
        industrietore: file(
          name: { eq: "ID_scene_Verglasung_242_9006_Nr3885" }
        ) {
          ...image
        }
        montage: file(name: { eq: "Service" }) {
          ...image
        }
        planung: file(name: { eq: "AdobeStock_115067132" }) {
          ...image
        }
        service: file(name: { eq: "AdobeStock_96507125" }) {
          ...image
        }
        schwingtore: file(name: { eq: "br_gar_uch_055" }) {
          ...image
        }
        schlupftueren: file(name: { eq: "Service4-" }) {
          ...image
        }
        garagenantrieb: file(name: { eq: "torantrieb-magic-innen-g60-0211" }) {
          ...image
        }
        drehtorantrieb: file(name: { eq: "swingx01" }) {
          ...image
        }
        schiebtorantrieb: file(name: { eq: "pullt_anw" }) {
          ...image
        }

        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/SWT_Weiß.png|Smart_Style_Anthrazit_02|g60 sarleinsbach trend szene1b_ohne nummernschild|eurotrend_weiß reihenhaus1|OSD_Scene_Eurostyle D338_7004_DSC8898/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 1500, maxHeight: 600, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Layout>
        <Container
          maxWidth="xl"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Wrapper>
            <FlexGrid bgCol={options.bgCol} id="Tore">
              <GridItem className="grid-text grid-item">
                <GridText color={options.color}>
                  <HR color={options.color} />
                  <h2>Tore</h2>
                  <p>
                    Kombinieren Sie Ihre individuellen Ansprüche mit der
                    Vielfalt und Sicherheit von Normstahl Sektionaltoren,
                    Industrietoren, Rolltoren und Schwingtoren.
                  </p>
                </GridText>
              </GridItem>

              <ProductGridItem
                fluid={props.sektionaltore.childImageSharp.fluid}
                aspectRatio={props.sektionaltore.childImageSharp.aspectRatio}
                height={"350px"}
                width={"350px"}
                headline={"Sektionaltore"}
                text={`Der Garant für Komfort, Vielfalt, Sicherheit und Leistung.
                  Wählen Sie aus der für komfortabelsten Möglichkeit Ihr Auto zu schützen.`}
                link={"/sektionaltore"}
              />
              <ProductGridItem
                fluid={props.industrietore.childImageSharp.fluid}
                aspectRatio={props.industrietore.childImageSharp.aspectRatio}
                height={"350px"}
                width={"350px"}
                headline={"Industrietore"}
                text={`Normstahl Industrie Sektionaltore und Verladesysteme bieten Ihnen bei den Faktoren Raum, Zeit und Robustheit wertvolle Pluspunkte.`}
                link={"/industrietore"}
              />
              <ProductGridItem
                fluid={props.schwingtore.childImageSharp.fluid}
                aspectRatio={props.schwingtore.childImageSharp.aspectRatio}
                height={"350px"}
                width={"350px"}
                headline={"Schwingtore"}
                text={`Die elegante Nach-Oben-Schwing-Bewegung gleicht der Umsetzung des Sesam-Öffne-Dich-Prinzips. Ein bekannter Klassiker auf dem Stand der Zeit.`}
                link={"/schwingtore"}
              />
            </FlexGrid>

            <FlexGrid bgCol={options.bgCol} id="Antriebe">
              <GridItem className="grid-text grid-item">
                <GridText color={options.color}>
                  <HR color={options.color} />
                  <h2>Antriebe</h2>
                  <p>
                    Mit unseren Normstahl Garagentorantrieben haben Sie es in
                    der Hand: Einfach sitzen bleiben und das Tor fährt per
                    Knopfdruck nach oben – und das ausgesprochen schnell.
                  </p>
                </GridText>
              </GridItem>

              <ProductGridItem
                fluid={props.garagenantrieb.childImageSharp.fluid}
                aspectRatio={props.garagenantrieb.childImageSharp.aspectRatio}
                height={"350px"}
                width={"350px"}
                headline={"Garagentorantriebe"}
                text={`Ein Normstahl Garagentorantrieb ist nicht nur komfortabel. Auch wenn es um Sicherheit geht, können Sie sich auf Ihren Toröffner hundertprozentig verlassen.`}
                link={"/garagentorantriebe"}
              />
              <ProductGridItem
                fluid={props.drehtorantrieb.childImageSharp.fluid}
                aspectRatio={props.drehtorantrieb.childImageSharp.aspectRatio}
                height={"350px"}
                width={"350px"}
                headline={"Drehtorantriebe"}
                text={`Ein Hoftorantrieb aus unserem Programm zeichnet sich durch robuste Technik, hervorragende Materialqualität und elegantes Design aus.`}
                link={"/drehtorantriebe"}
              />
              <ProductGridItem
                fluid={props.schiebtorantrieb.childImageSharp.fluid}
                aspectRatio={props.allImageSharp.edges[1].node.aspectRatio}
                height={"350px"}
                width={"350px"}
                headline={"Schiebetorantriebe"}
                text={`Unsere Antriebe für Schiebetore sind sehr robust, überzeugen mit Ihrer Materialqualität und sehen dabei noch gut aus. `}
                link={"/schiebetorantriebe"}
              />
            </FlexGrid>

            <FlexGrid bgCol={options.bgCol} id="Service">
              <GridItem className="grid-text grid-item">
                <GridText color={options.color}>
                  <HR color={options.color} />
                  <h2>Service</h2>
                  <p>
                    Erwarten Sie von uns keine 08/15-Lösung, sondern eine
                    perfekt zu Ihren individuellen Vorstellungen passende Tor-
                    oder Türlösung, welche auch die baulichen Bedingungen und
                    Spezifikationen vor Ort berücksichtigt.
                  </p>
                </GridText>
              </GridItem>

              <ProductGridItem
                fluid={props.planung.childImageSharp.fluid}
                aspectRatio={props.planung.childImageSharp.aspectRatio}
                height={"350px"}
                width={"350px"}
                headline={"Beratung und Planung"}
                text={`Wir beraten Sie von der Planung bis hin zur Umsetzung und gestalten gemeinsam mit Ihnen eine perfekte Lösung für Ihre Garage.`}
                link={"/services"}
              />

              <ProductGridItem
                fluid={props.montage.childImageSharp.fluid}
                aspectRatio={props.montage.childImageSharp.aspectRatio}
                height={"350px"}
                width={"350px"}
                headline={"Montage und Sanierung"}
                text={`Egal ob Montage eines neuen Garagentors oder Sanierung / Modernisierung des bestehenden Tors. Wir sind für Sie da.`}
                link={"/services"}
              />

              <ProductGridItem
                fluid={props.service.childImageSharp.fluid}
                aspectRatio={props.service.childImageSharp.aspectRatio}
                height={"350px"}
                width={"350px"}
                headline={"Service und Wartung"}
                text={`Bei uns zählt das Prinzip “Alles aus einer Hand” deshalb sehen wir uns auch bei der Wartung nach der Montage Ihres Garagentors in der Verantwortung.`}
                link={"/services"}
              />
            </FlexGrid>
          </Wrapper>
        </Container>
      </Layout>
    )}
  />
)

export default Products
